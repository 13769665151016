<template>
  <div>
    <h3 class="text-center font-weight-normal">
      Confirm sign up
    </h3>
    <p class="text-center">
      You are about to create a new SolidProfessor account using your
      {{ provider }} account.
    </p>
    <div class="form-group text-center">
      <b-button varient="success" type="submit" @click="confirmSignup">
        Create my account
      </b-button>
    </div>
    <div class="col text-center">
      <small>By proceeding to create your account and use SolidProfessor, you are
        agreeing to our
        <a href="https://www.solidprofessor.com/terms-and-conditions/" target="_blank" class="btn-link">Terms of Use</a>
        and
        <a href="https://www.solidprofessor.com/privacy/" target="_blank" class="btn-link">Privacy Policy</a>.
      </small>
    </div>
    <div class="text-center mt-3">
      <small>Don't create my account.
        <b-link :to="{ name: 'Login', query: query_params }">Back to Log in</b-link>
      </small>
    </div>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent';
import axios from 'axios';
import analyticsClickHandler from '@/utils/mixins/Analytics';

export default {
  name: 'OAuthConfirm',
  extends: BaseComponent,
  mixins: [analyticsClickHandler],
  data() {
    return {
      provider: null,
      query_params: {},
      submitting: false
    };
  },
  beforeRouteEnter(to, from, next) {
    // make sure have necessary oauth state
    axios.get(process.env.SPENV.ACCOUNTS_API_URL + '/oauth/state').then(
      (response) => {
        next(vm => {
          vm.$data.provider = response.data.provider;
          if (response.data.affkey) {
            vm.$data.query_params.affkey = response.data.affkey;
          }
          if (response.data.campaign_id) {
            vm.$data.query_params.campaign_id = response.data.campaign_id;
          }
          if (response.data.gclid) {
            vm.$data.query_params.gclid = response.data.gclid;
          }
          if (response.data.return_url) {
            vm.$data.query_params.return_url = response.data.return_url;
          }
        });
      },
      () => {
        next({name: 'Login'});
      }
    );
  },
  methods: {
    confirmSignup() {
      this.submitting = true;
      axios.post(process.env.SPENV.ACCOUNTS_API_URL + '/api/oauth/confirm').then(
        (response) => {
          this.analyticsClickEvent('Account', 'New Account', 'New Account created');
          this.submitting = false;
          this.loginRedirect(response);
        },
        (error) => {
          this.$emit('alert', error.response.data);
          // display error message
          this.submitting = false;
        }
      );
    }
  }
};
</script>
